import React from 'react';


const MarketingExpertMessage = () => {
  return (
    <div style={styles.container} className='hilight'>

      <p style={{ color: '#616C6F' }}> <span style={{ color: '#6ab04c' }}> </span> What's up , I'm Jatin</p>
      <p style={{ color: '#616C6F' }} className="highlight">I am founder of <span style={{ color: '#1B9CFC' }}>Vachyum, </span> lead innovative collaborations.</p>
      <p style={{ color: '#616C6F' }}>Reach out to me at jatin@vachyum.com</p>
    </div>
  );
};

const styles = {
  container: {
    
    fontFamily: 'Montserrat, sans-serif ',
    
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  greeting: {
    fontSize: '15px',
    
    
  },

  
};

export default MarketingExpertMessage;
